@import '../../../assets/index.scss';

.complete-container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 100vh;
  color: $primary;

  h1 {
    color: $primary;
  }

  p {
    color: $grey7-primary-text;
  }

  .ant-btn {
    border-radius: 20px;
    border-color: $accent-blue1;
    color: $accent-blue1;
    padding: 0 20px;
  
    &:hover {
      border: 1px solid $primary;
      background: $primary-hover;
      color: white;
    }
  }
}