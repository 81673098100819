@import "../../assets/index.scss";

.vnc-viewer {
  canvas {
    width: 100%;
    height: 100%;
    min-width: 640px;
    min-height: 480px;
  }
}

.vnc-btn-bar {
  height: 25px;
  width: auto;
  display: flex;
  justify-content: flex-end;
  background-color: $vnc-background;
}

.ant-btn.vnc-btn {
  height: 25px;
  padding: 0;
  background-color: $vnc-background;
  color: white;
}