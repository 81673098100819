// default colors
$grey1: #fbfbfb;
$grey2: #f5f5f5;
$grey3: #e0e0e0;
$grey4: #d9d9d9;
$grey4-disabled: #c8c8c8;
$grey5: #a7a7a7;
$grey6: #838383;
$grey7-primary-text: #404040;
$grey8: #d7d7d7;

// brand colors
$primary: #006dc9;
$primary-hover: #2359a3;
$primary-disabled: #93b3e5;
$secondary-hover-fill: #edf4ff;
$accent-blue1: #1fa0ff;
$accent-blue2: #98d5ff;
$footer-grey: #959595;

// notification colors
$error: #da1e27;
$error-light: #fff5f5;
$warning: #eb6d12;
$warning-light: #fff6ef;
$information: #00c4ff;
$information-light: #f0f7ff;
$success: #27d397;
$success-light: #f2fcf9;
$tooltip: #4c4e6a;

// vnc colors
$vnc-background: #282828;
