@import "../../../assets/index.scss";

.ant-table .ant-table-container .ant-table-content .ant-table-thead {
  color: #2c6bcc;
}

.ant-table-thead {
  color: #2c6bcc;
  background-color: #2c6bcc;
}

.ant-table-tbody {
  background-color: white;
}

.stats-table {
  color: #2c6bcc;
  background-color: #2c6bcc;
}

.button-container {
  text-align: right;
}

.manage-btn {
  color: #2c6bcc;
  width: fit-content;
}

.vm-container {
  box-shadow: 3px 3px 5px 0 rgba(0, 0, 0, 0.1);
}

.vm-footer {
  padding: 10px;
  background: white;

  .action-btn {
    border-radius: 3px;
    border: 1px solid $primary;
    margin-right: 20px;
    font-weight: 600;
    background: $primary;
    color: white;

    &[disabled] {
      color: rgba(0, 0, 0, 0.25);
      border-color: $grey4;
      background: $grey2;
    }
  }

}

.heading {
  color: $primary;
}