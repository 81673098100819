@import '../../assets/index.scss';

.wizard-container {
  max-width: 1280px;

  .ant-spin {
    width: 100%;
  }

  .wizard-steps {
    padding-top: 15px;
    border-right: 2px solid $grey4-disabled;
    color: $primary;
    padding-right: 20px;
    min-width: 200px;
    margin-right: 40px;

    .wizard-steps-title {
      margin-bottom: 40px;
      font-weight: 600;
    }

    .ant-steps-vertical {

      .ant-steps-item-active {
        .ant-steps-item-icon {
          background: $accent-blue1;
        }
        .ant-steps-item-title {
          color: $accent-blue1;
        }
      }

      .ant-steps-item-wait, .ant-steps-item-finish {
        .ant-steps-item-title {
          color: $primary-hover;
        }

        .ant-steps-item-icon {
          color: $primary-hover !important;
          border-color: $primary-hover;

          .ant-steps-icon {
            color: inherit;
          }
        }
      }

      .ant-steps-item {
        .ant-steps-item-container {
          height: 125px;

          .ant-steps-item-tail {
            &::after {
              height: 80px !important;
              position: relative;
            }
          }
          .ant-steps-item-icon {
            color: $accent-blue1;
          }
        }

        &:not(.ant-steps-item-finish) {
          .ant-steps-item-tail {
            &::after {
              background-color: $grey4-disabled;
            }
          }
        }
      }
    }
  }

  .wizard-content {
    padding-top: 15px;

    .ant-select-multiple {
      .ant-select-selector {
        height: 100%;
        padding: 0 8px;

        .ant-select-selection-item {
          background: $primary;
          height: 24px;
          border-radius: 2px;
          border: 1px solid $primary;
          margin-right: 8px;
          padding: 0 8px;

          .ant-checkbox-wrapper {
            .ant-checkbox {
              display: none;
            }
            span {
              color: white;
              padding: 5px 5px 0 0 ;
            }
          }

          .ant-select-selection-item-remove {
            color: white;
          }
        }
      }
    }

    .wizard-step-title {
      margin-bottom: 40px;
      color: $primary;
      font-weight: 600;
    }

    .ant-col-12 {
      .ant-form-item {
        width: 360px;
      }
    }

    .ant-col-24 {
      .ant-form-item {
        width: 796px;
      }
    }

    .ant-col .ant-form-item-label {
      font-size: 16px;

      label {
        color: $grey7-primary-text;
        font-size: 16px;
      }
    }

    .cloud-area {
      background: #fff;
      width: 224px;
      height: 160px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 20px;
      margin-bottom: 20px;
      box-shadow: 2px 2px 6px 0px rgba(187,187,187,0.5);

      &:hover {
        box-shadow: 4px 4px 6px 0px rgba(187,187,187,0.5);
      }

      &.active {
        border: 1px solid $accent-blue1;
      }

      .cloud-area-check {
        font-size: 24px;
        display: block;
        position: absolute;
        top: -12px;
        right: -12px;
        color: $accent-blue1;
        z-index: 1;
        background: white;
        border-radius: 12px;
      }

      &.add-profile {
        flex-direction: column;

        .anticon-plus {
          color: $primary;
          font-size: 40px;
          margin-bottom: 10px;
        }
      }

      .provider-logo-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        text-align: center;

        svg {
          margin-bottom: 10px;
        }
      }
    }

    .item-label-descriptor {
      .ant-form-item-control-input-content {
        display: flex;
        flex-direction: row;
        align-items: center;

        .item-label-description {
          padding: 10px;
          background: white;
          border: 1px solid $grey5;
          border-top-left-radius: 4px;
          border-bottom-left-radius: 4px;
          height: 40px;
          min-width: 150px;
          border-right: none;
        }

        input.modal-item-controller {
          border-top-left-radius: 0;
          border-bottom-left-radius: 0;
        }
      }
    }

    .ant-form {
      .ant-row {
        .ant-col {
          &.wizard-col {
            min-height: 110px;
          }
        }
      }
    }

    .prefill-error-container {
      .message-container {
        display: flex;
        align-items: center;

        .anticon-exclamation-circle {
          color: red;
          font-size: 30px;
          margin-right: 15px;
        }
      }
      .error-back-btn {
        margin-top: 20px;
      }
    }
  }

  .wizard-footer {
    background: white;
    position: absolute;
    bottom: 40px;
    height: 70px;
    width: 100%;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 3px -3px 5px 0px rgba(0, 0, 0, 0.1);

    .ant-btn {
      width: 152px;
      height: 32px;
      border-radius: 2px;
      background: $primary;
      border-color: $primary;

      &[disabled] {
        border-color: $primary-disabled;
        background: $primary-disabled;
        color: white;
      }

      &:hover {
        background: $primary-hover;
        border-color :$primary-hover;
      }

      &:first-child {
        margin-right: 24px;
        background: white;
        border: 1px solid $primary;
        color: $primary;

        &:hover {
          background: $secondary-hover-fill;
          border-color :$primary-hover;
          color: $primary-hover;
        }

        &[disabled] {
          color: $grey5;
          border-color: $grey5;
          background: #fff;
        }
      }
    }
  }
}

.ant-select-dropdown {
  .ant-select-item-option-selected {
    &:not(.ant-select-item-option-disabled) {
      background-color: white;
    }

    &.ant-select-item-option-active {
      &:not(.ant-select-item-option-disabled) {
        background-color: $grey2;
      }
    }

    .ant-select-item-option-state {
      display: none;
    }
  }
}