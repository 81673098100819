@import "../../assets/index.scss";

.mh-100 {
  min-height: 100vh;
}

.ant-layout {
  .ant-layout-header {
    box-shadow: 0px 2px 5px 0px rgba(0,0,0,0.1);
    z-index: 1;
    margin-bottom: 10px;
    background-color: white;

    .ant-row {
      flex-wrap: nowrap;
    }

    .fluid-title {
      height: 64px;
      display: flex;
      align-items: center;

      span {
        margin: 0 80px 0 16px;
        padding: 0;
        font-size: 20px;
        color: $accent-blue1;
      }
    }
    .ant-menu.ant-menu-light {
      height: 64px;
      border-bottom: none;

      .ant-menu-item {
        height: 64px;
        margin: 0 40px 0 0 !important;
        top: 0px;

        &:first-child {
          margin-left: 0 !important;
        }
      }

      span {
        font-size: 15px;
        color: $primary;
      }

      .ant-menu-item-selected,
      .ant-menu-item-active {
        background-color: white;
        border-bottom: 2px solid $accent-blue1;

        span {
          color: $accent-blue1;
        }
        &::after {
          border-bottom: transparent;
        }
      }
    }
    .ant-menu-submenu {
      height: 64px;
      margin: 0 40px 0 0 !important;
      top: 0;

      &.ant-menu-submenu-horizontal {
        height: 64px;

        :hover {
          color: $accent-blue1;
        }

        .ant-menu-submenu-selected {
          border-bottom: 2px solid $accent-blue1;
          height: 64px;
          color: $accent-blue1;
        }

        &.ant-menu-submenu-active:not(.ant-menu-submenu-selected) {
            border-bottom: 0;
            border-bottom-color: white;
        }

        .anticon-caret-down {
          margin-left: 5px;
          margin-right: 0;
          color: inherit;
        }
      }
    }
  }

  .notification-container {
    margin-right: 8px;
  }

  .cluster-selector {
    margin-left: auto;
    width: auto;
    margin-right: 0 !important;
    line-height: 18px;
    position: relative;
    &.profile-button {
      margin-left: 25px;
    }
    b {
      color: rgb(0, 109, 201);
    }
    span.cluster {
      color: rgb(0, 109, 201);
      font-size: 12px;
    }
    div.icon {
      color: rgb(0, 109, 201);
      position: absolute;
      right: -20px;
      top: 10px;
    }

    .ant-select {
      top: 8px;
      min-width: 120px;
      margin-right: 0 !important;

      .ant-select-selector {
        color: $primary;

        .ant-select-selection-item {
          font-size: 12px;
          line-height: 55px !important;

          &:before {
            content: 'Environment';
            display: block;
            position: absolute;
            font-weight: 600;
            top: -16px;
            font-size: 15px;
          }
        }
      }

      .ant-select-arrow {
        color: $primary;
        user-select: none;
        display: block;
        width: 25px;
        height: 25px;
        right: 0px;

        .anticon-down {
          color: $primary;
        }
      }

      &.ant-select-open {
        .ant-select-arrow {
          top: 6px;
          color: rgba(0, 0, 0, 0.25);
          transition: color 0.3s;
        }
      }

    }
  }
}

.sidebar-notification {
  font-size: 15px;
  color: $primary;
  cursor: pointer;
  margin-left: 15px;

  &:hover,
  &:active {
    color: $accent-blue1;
  }

  .anticon-bell {
    margin-right: 3px;
  }

  .ant-badge {
    margin-left: 0;
    top: -2px;
  }
}

.ant-popover {
  .messages-card {
    box-shadow: none !important;

    .message-content {
      padding: 0 30px 0 20px;
    }
  }
}

.ant-menu-submenu-popup.menubar-submenu-popup {

  .ant-menu-sub {
    border: 1px solid rgba(0,0,0,0.1);
    border-radius: 2px;
  }

  &:after {
    position: absolute;
    top: -19px;
    right: 0;
    bottom: 0;
    left: 20px;
    z-index: 1;
    width: 20px;
    height: 20px;
    opacity: 1;
    content: " ";
    border-bottom: 15px solid white;
    border-left: 15px solid transparent;
    border-right: 15px solid transparent;
  }

  &:before {
    position: absolute;
    top: -20px;
    right: 0;
    bottom: 0;
    left: 20px;
    z-index: -1;
    width: 20px;
    height: 20px;
    opacity: 1;
    content: " ";
    border-bottom: 15px solid rgba(0,0,0,0.1);
    border-left: 15px solid transparent;
    border-right: 15px solid transparent;
  }
}

.cluster-selector-dropdown {

}

.navigation-bar {
  &.active-wizard {
    justify-content: center;
    position: relative;
    min-width: 1100px;

    .fluid-title {
      margin-right: 20px;
      position: absolute;
      left: 0;

      span {
        margin-right: 0;
      }
    }
  }

  .navigation-wizard-steps {
    display: flex;
    flex-direction: row;

    .navigation-wizard-step {
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-right: 41px;
      color: $primary;
      font-weight: 600;

      .navigation-wizard-step-number {
        background: white;
        width: 30px;
        height: 30px;
        border-radius: 20px;
        border: 1px solid $primary;
        position: relative;
        margin: auto 8px auto 0;
        text-align: center;
        line-height: 27px;
      }

      &.active {
        color: $accent-blue1;

        .navigation-wizard-step-number {
          border-color: $accent-blue1;
          background-color: $accent-blue1;
          color: white;
        }
      }
    }
  }

  .navigation-wizard-close {
    position: absolute;
    right: 50px;
    top: 2px;
  }
}

.fluid-logo {
  width: 40px;
  height: 40px;
  color: $accent-blue1;
  fill: $accent-blue1;

  .icon-stroke {
    stroke: $accent-blue1;
  }
}