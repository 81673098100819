@import "../../assets/index.scss";

.nested-cluster-detail-info {
  background: white;
  padding: 25px 30px;
  box-shadow: 3px 3px 5px 0 rgba(0, 0, 0, 0.1);
  margin-bottom: 40px;
  display: flex;
  flex-direction: column !important;

  .nested-cluster-detail-sub-title {
    color: rgba(0, 0, 0, 0.8);
  }
}

.nested-cluster-footer {
  margin-top: 30px;
  padding-top: 20px;

  .action-btn {
    border-radius: 3px;
    border: 1px solid $primary;
    margin-right: 20px;
    font-weight: 600;
    background: $primary;
    color: white;
  }

  .destroy-btn {
    border-radius: 3px;
    background-color: $error;
    font-weight: 600;
  }
}

.kube-details {
  font-weight: 700;
  padding-right: 10px;
}

.kube-stats-info {
  background: white;
  padding: 25px;
  box-shadow: 3px 3px 5px 0 rgba(0, 0, 0, 0.1);
  margin-bottom: 40px;
  min-width: 1010px;
  
  .ant-spin-nested-loading {
    min-width: 1076px;
  }

  .stats-sub-title {
    color: rgba(0, 0, 0, 0.3);
  }

  .stats-container {
    padding-bottom: 20px;
    border-bottom: 1px solid $grey3;
    width: 100%;

    .stats-info-block {
      display: flex;
      flex-direction: row;
      align-items: center;
      border-left: 1px solid $grey3;
      height: 60px;
      padding: 0 20px;
      min-width: 200px;

      .stats-info-description {
        display: flex;
        flex-direction: column;
        justify-content: center;
      }

      .stats-value {
        font-size: 25px;
      }

      &:first-child {
        border-left: none;
      }
    }
  }

  .kube-actions-container {
    padding-top: 20px;
    width: 100%;
    justify-content: start;

    .action-btn {
      border-radius: 3px;
      border: 1px solid $primary;
      margin-right: 20px;
      font-weight: 600;
      background: $primary;
      color: white;
    }

    .destroy-container {
      text-align: right;
    }
  }
}

.action-button-container {
  padding-bottom: 20px;
  padding-top: 10px;

  .action-col {
    background: white;
    text-align: center;
    color: $grey7-primary-text;
    box-shadow: 3px 3px 5px 0 rgba(0, 0, 0, 0.1);
    padding-bottom: 15px;
    padding-top: 15px;
    min-width: 200px;
  
    .action-icon {
      height: 40px;
      margin-bottom: 10px;
    }

    .spot-icon {
      height: 55px;
      margin-top: -5px;
      margin-bottom: 5px;
    }
  
    .ant-btn {
      border-color: $accent-blue1;
      color: $accent-blue1;
  
      &:hover {
        border: 1px solid $primary;
        background: $primary-hover;
        color: white;
      }
    }
  }
  
}
