@import "../../assets/index.scss";

.expanded-cloud-info {
  padding: 12px 36px;

  >div {
    height: 35px;
    padding: 0 16px;
  }

  >div:nth-child(even) {
    background-color: #f1f5f8;
    border-radius: 2px;
  }

  >div:last-child {
    height: 66px;
    padding: 24px 0 8px;
  }

  button {
    width: 100%;
    border-radius: 3px;
    font-weight: 600;
  }

  .shutdown {
    color: white;
    background-color: $error;
  }

  .delete {
    color: #a3a9b3;
    border: 1px solid #a3a9b3;
  }
}

.expanded-cloud-label {
  font-size: 15px;
  font-weight: 600;
  color: $grey7-primary-text;
}

.expanded-cloud-content {
  font-size: 15px;
  color: $primary-hover;
}

.cloud-job-container {
  display: flex;

  .cloud-job-label {
    margin-right: 20px;
    width: 100px;
  }

  .cloud-job-value {
    font-weight: normal;
  }
}