@import "../../assets/index.scss";

.tabs-container {
  .tabs-navigation-container {
    margin-bottom: 15px;
    justify-content: space-between;
    align-items: center;

    .tab-nav-wrapper {
      display: flex;
      box-shadow: 0 0 10px -4px rgba(0, 0, 0, 0.2);

      .tab-nav {
        background: white;
        padding: 10px 20px;
        display: flex;
        justify-content: center;
        min-width: 250px;
        border-bottom: 2px solid white;
        transition: border 0.5s ease;

        &.active {
          background: rgba(31, 160, 255, 0.1);
          color: $primary;
          font-weight: 600;
          border-bottom-color: $primary;
        }
      }
    }
  }
  .tab-direction-column {
    flex-direction: column;
  }
};