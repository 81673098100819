@import "../../../assets/index.scss";

.ant-card.getStarted-card {
  height: 100%;
  box-shadow: 3px 3px 5px 0px rgba(0, 0, 0, 0.1);
  min-width: 200px;
  border: none;

  .ant-card-body {
    padding: 0 24px 18px;
  }

  .ant-card-head-title {
    color: $primary;
  }

  .ant-card-head {
    border: none;

    &:after {
      width: 100%;
      border: 1px solid rgba(0, 0, 0, 0.1);
      margin-bottom: 10px;
    }
  }

  .card-header-view-all {
    color: $primary;
    font-size: 16px;
    border: none;
    box-shadow: none;
    padding: 3px;

    :hover {
      color: $primary-hover;
    }
  }
}

.ant-card .ant-card-actions {
  margin: 0 24px;
  font-size: 12px;
  color: #7c8695;

  > li {
    text-align: right;
    margin: 4px 0 16px;

    button {
      font-size: 12px;
      color: #7c8695;
      border: none;
      padding: 0;
    }
  }
}

.message-row {
  min-height: 60px;
  border-radius: 2px;
  border-bottom: 1px solid #e3e7ea;
  padding: 10px 0;

  &:last-child {
    border-bottom: none;
  }

  &:hover {
    background-color: #f1f5f8;
  }
}

.message-row.active {
  background-color: #f1f5f8;
  border-left: 4px solid #2c6bcc;
}

.message-content {
  align-items: center;
  display: flex;

  svg {
    position: relative;
    margin-right: 20px;
    height: 32px;
    width: 32px;
  }

  padding-left: 8px;
  max-width: 350px;

  span {
    display: block;
  }

  &__title {
    font-size: 16px;
    font-weight: 600;
    color: $grey7-primary-text;
  }

  &__body {
    color: #788599;
  }
}

.message-date {
  padding-right: 16px;
  font-size: 12px;
  color: #9ca5b4;
}

.env-stats-info {
  background: white;
  padding: 25px 30px;
  box-shadow: 3px 3px 5px 0 rgba(0, 0, 0, 0.1);
  margin-bottom: 40px;
  display: flex;
  flex-direction: column !important;
  min-width: 1010px;

  .stats-sub-title {
    color: rgba(0, 0, 0, 0.3);
  }

  .stats-info-block {
    display: flex;
    flex-direction: row;
    align-items: center;

    .stats-info-description {
      display: flex;
      flex-direction: column;
      justify-content: center;
    }

    .stats-value {
      font-size: 25px;
    }

    .icon-block {
      width: 60px;
      height: 60px;
      border-radius: 5px;
      margin-right: 15px;
      font-size: 40px;
      text-align: center;
      color: white;
      background: $accent-blue1;
      border: 2px solid $accent-blue1;

      .anticon {
        position: relative;
        top: -5px;
      }
    }
  }
}

.heading {
  color: $primary;
}

.alert {
  margin-bottom: 30px;
}
