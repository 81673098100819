@import "../../assets/index.scss";

.ant-table-title {
  border-bottom: 1px solid #e3e7ea;
  padding: 8px 0 8px 0 !important;

  .table-title {
    font-size: 24px;
    font-weight: 600;
  }

  .pagination-amount {
    font-size: 14px;
    color: #949ba7;
  }

  .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    border: none;
  }
}

.content-layout .ant-table {
  background: transparent;
}

.provider-container {
  .table-logo-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    border-radius: 24px;
    border: 1px solid $grey3;
    margin-right: 20px;

    svg {
      margin-right: 0px;
      padding: 6px;
    }
  }
}

.ant-spin-container {
  .ant-table {
    .ant-table-container {
      table {
        position: relative;
        border: none;
        width: calc(100% + 1.5px);
        border: 1px solid $grey3;

        thead, tbody, tr, td {
          position: relative;
        }

        .ant-table-thead {
          z-index: 1;

          th {
            background-color: $accent-blue1;
            color: white;
            font-size: 14px;
            border-bottom: none;
            padding-left: 24px;
            height: 56px;
            position: relative;

            &:first-child {
              padding-left: 25px;
            }

            &:hover {
              background-color: $accent-blue1;
            }

            &:last-child {
              border-right: none;
            }

            &:not(.action-column):not(.ant-table-row-expand-icon-cell):not(:last-child):after {
              content: '';
              display: block;
              width: 1px;
              height: 19px;
              background: #fff;
              position: absolute;
              right: 0;
              top: calc(50% - 9px);
              opacity: 0.5;
            }
          }

          tr {
            box-shadow: 2px 2px 6px 0px rgb(0 0 0 / 20%);
          }
        }

        .ant-table-tbody {
          tr {
            td {
              padding-left: 24px;

              &.action-column {
                font-weight: bold;
                padding: 0 20px 0 10px;

                .ant-btn {
                  border-radius: 20px;
                  border-color: $accent-blue1;
                  color: $accent-blue1;
                  padding: 0 30px;

                  &:hover {
                    border: 1px solid $primary;
                    background: $primary-hover;
                    color: white;
                  }
                }

                .more-action-button {
                  border-radius: 20px;
                  color: $accent-blue1;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  width: 32px;
                  height: 16px;

                  &:hover {
                    border: 1px solid $primary;
                    background: $secondary-hover-fill;
                  }

                  svg {
                    width: 20px;
                  }
                }
              }
            }
          }

          .ant-table-placeholder {
            background: white;

            .ant-empty {
              padding: 20px;

              .ant-empty-image {
                height: 40px;
                margin-bottom: 0;
              }

              .ant-empty-description {
                font-weight: 600;
              }
            }
          }
        }
      }

      .ant-table-column-sorters {
        display: flex;
        justify-content: space-between;
        padding: 4px 4px 4px 0;

        .ant-table-column-sorter {
          color: white;
        }
      }

      .ant-table-tbody > tr > td {
        font-size: 14px;
        padding: 8px;
        z-index: 0;
      }

      .ant-table-tbody > tr.custom-table-expanded-row > td {
        border-bottom: none;
      }

      .ant-table-tbody > tr.ant-table-expanded-row {
        border-radius: 4px;
        box-shadow: 1px 3px 10px 0 rgba(232, 232, 232, 0.78);
        border: solid 1px rgba(0, 0, 0, 0.06);
      }

      .ant-table-column-sorters {
        display: flex;
        color: white;
        justify-content: space-between;

        .ant-table-column-sorter {
          color: inherit;
        }
      }

      .custom-table-row {
        background-color: white;
        height: 60px;
        border: solid 1px $grey3;
        position: relative;

        &:hover {
          color: inherit;
          .ant-table-cell {
            color: inherit;
          }

        }
      }

      .custom-table-expanded-row,
      .custom-table-expanded-row:hover,
      .ant-table-tbody > tr.ant-table-row:hover {
        td {
          background-color: white;
          color: $primary;
          font-size: 14px;
          font-weight: 600;

          &:first-child::before {
            content: '';
            position: absolute;
            display: block;
            box-shadow: 0px 2px 5px 0px red;
          }
        }
      }
    }
  }

  .ant-table-pagination.ant-pagination {
    background: white;
    margin: 0;
    padding: 10px;
    box-shadow: 0px -2px 5px 0px rgba(0, 0, 0, 0.1);

    .pagination-button-container {
      .ant-btn {
        height: 24px;
        padding: 0;
        width: 40px;
        min-width: unset;
        border: none;

        &:hover {
          background: $secondary-hover-fill;
        }

        &::after {
          display: none;
        }
      }
    }

    .ant-pagination-total-text {
      margin-right: 20px;
      color: $primary;
      font-weight: 600;
    }

    .ant-pagination-item {
      &:not(.ant-pagination-item-1) {
        display: none;
      }

      input {
        height: 24px;
        width: 50px;
        text-align: center;
        background: $primary;
        color: white;
        border: none;
      }
    }

    .ant-pagination-item-active {
      font-weight: 600;
      background: white;
      border: none;

      a {
        color: white;
      }
    }
  }
}

.more-action-menu {
  cursor: pointer;
  font-size: 14px;
  line-height: 26px;
  margin: 0 -16px;
  padding: 8px 32px;

  &:hover {
    background-color: $grey2;
  }
}

