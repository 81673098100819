@import "../../assets/index.scss";

.virtual-machine-detail-info {
  background: white;
  padding: 25px 30px;
  box-shadow: 3px 3px 5px 0 rgba(0, 0, 0, 0.1);
  margin-bottom: 40px;
  display: flex;
  flex-direction: column !important;

  .virtual-machine-detail-sub-title {
    color: rgba(0, 0, 0, 0.8);
  }
}

.virtual-machine-footer {
  margin-top: 30px;
  padding-top: 20px;

  .action-btn {
    border-radius: 3px;
    border: 1px solid $primary;
    margin-right: 20px;
    font-weight: 600;
    background: $primary;
    color: white;
  }

  .destroy-btn {
    border-radius: 3px;
    background-color: $error;
    font-weight: 600;
  }
}

.log-events-panel {
  background: white;
  padding: 20px 30px;
  box-shadow: 3px 3px 5px 0px rgba(0, 0, 0, 0.1);
}

.log-events-container,
.log-events-panel {
  height: 100%;
  display: flex;
  flex-direction: column;

  .ant-table {
    .ant-table-container {
      table {
        width: 100%;
      }

      &:after {
        box-shadow: none;
      }
    }

    .ant-table-tbody {
      .ant-table-row {
        .ant-table-cell {
          &:last-child {
            text-align: left;
          }
        }
      }
    }
  }

  .header {
    color: $primary;

    .row {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      height: 32px;
    }

    &:after {
      content: '';
      display: block;
      border-bottom: 1px solid $grey3;
      padding-bottom: 10px;
    }
  }

  .no-data-container {
    margin-top: 40px;
  }

  .header-description {
    color: $primary;
    display: flex;
    align-items: baseline;
    line-height: 1;
    font-weight: 600;

    .item-count {
      font-size: 26px;
      margin-right: 5px;
    }
  }

  .container-body{
    overflow: auto;

    .details {
      font-weight: 300;
      padding-top: 10px;
    }    

    .events-table {
      height: 100%;
    
      .ant-table {
        height: 100%;
    
        .ant-table-container {
          height: 100%;
        }
    
        .ant-table-column-has-sorters {
    
          .ant-table-column-sorters {
            padding: 0 16px 0 0 !important;
          }
        }
      }
    }
  }
}

.header-description {
  color: $primary;
  display: flex;
  padding-bottom: 20px;
}

.machine-not-available {
  .message-container {
    display: flex;
    align-items: center;

    .anticon-exclamation-circle {
      color: red;
      font-size: 30px;
      margin-right: 15px;
    }
  }

  .view-machines-btn {
    margin-top: 20px;
  }
}

.vm-stats-info {
  background: white;
  padding: 25px;
  box-shadow: 3px 3px 5px 0 rgba(0, 0, 0, 0.1);
  margin-bottom: 40px;
  min-width: 1010px;

  .ant-spin-nested-loading {
    min-width: 1076px;
  }

  .stats-sub-title {
    color: rgba(0, 0, 0, 0.3);
  }
  
  .stats-container {
    padding-bottom: 20px;
    border-bottom: 1px solid $grey3;
    width: 100%;

    .stats-info-block {
      display: flex;
      flex-direction: row;
      align-items: center;
      border-left: 1px solid $grey3;
      height: 60px;
      min-width: 200px;
      padding: 0 20px;

      .stats-info-description {
        display: flex;
        flex-direction: column;
        justify-content: center;
      }

      .stats-value {
        font-size: 25px;
      }

      &:first-child {
        border-left: none;
      }
    }
  }

  .vm-actions-container {
    padding-top: 20px;
    width: 100%;
    justify-content: start;

    .destroy-container {
      text-align: right;

      .destroy-btn {
        border-radius: 3px;
        background-color: $error;
        font-weight: 600;
        color: white;
      }
    }

    .action-container {
      .action-btn {
        border-radius: 3px;
        border: 1px solid $primary;
        margin-right: 20px;
        font-weight: 600;
        background: $primary;
        color: white;

        &[disabled] {
          color: $grey5;
          border-color: $grey5;
          background: $grey2;
        }
      }
    }

  }
}

.vm-details-container {
  background: white;
  padding: 20px;
  box-shadow: 3px 3px 5px 0 rgba(0, 0, 0, 0.1);
  margin-bottom: 40px;

  .stats-sub-title {
    color: rgba(0, 0, 0, 0.3);
  }

  .stats-info-block {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 10px 0px;
    border-bottom: 1px solid $grey3;

    .stats-info-description {
      display: flex;
      flex-direction: column;
      justify-content: center;
    }

    .stats-value {
      font-size: 16px;
    }
  }

  .stats-info-block-bottom {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 10px 0px;

    .stats-info-description {
      display: flex;
      flex-direction: column;
      justify-content: center;
    }

    .stats-value {
      font-size: 16px;
    }
  }

  .vm-actions-container {
    padding-top: 20px;
    width: 100%;
    justify-content: start;
  }
}