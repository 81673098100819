@import "../../assets/index.scss";

.extended-footer {
  margin-top: 30px;
  border-top: 1px solid $grey4-disabled;
  padding-top: 25px;
  background: white;

  .state-btn {
    border-radius: 3px;
    background-color: $error;
    font-weight: 600;

    &.enable {
      background-color: $primary !important;
      border-color: $primary !important;
    }
  }

  .destroy-btn {
    border-radius: 3px;
    background-color: $error !important;
    border-color: $error !important;
    font-weight: 600;
    color: white;
    margin-right: 10px;
  }
}

.expanded-cloud-label {
  font-size: 15px;
  font-weight: 600;
  color: $grey7-primary-text;
}

.expanded-cloud-content {
  font-size: 15px;
  color: $primary-hover;
}

.cloud-not-available {
  .message-container {
    display: flex;
    align-items: center;

    .anticon-exclamation-circle {
      color: red;
      font-size: 30px;
      margin-right: 15px;
    }
  }

  .view-clouds-btn {
    margin-top: 20px;
  }
}

.ant-card.chart-card {
  border: none;
  box-shadow: 3px 3px 5px 0px rgba(0, 0, 0, 0.1);
  height: 100%;

  .ant-card-body {
    padding: 20px 24px 20px;

    .ant-spin-container {
      box-shadow: none;
    }
  }

  .ant-card-head-title {
    color: $primary;
  }

  .ant-card-head {
    border: none;

    &:after {
      width: 100%;
      border: 1px solid rgba(0, 0, 0, 0.1);
    }
  }

  .card-header-view-all {
    color: $primary;
    font-size: 16px;
    border: none;
    box-shadow: none;

    :hover {
      color: $primary-hover;
    }
  }
}

.cloud-block {
  .ant-col {
    margin-bottom: 20px;
  }
  .chart-block {
    border-left: 2px solid rgba(0, 0, 0, 0.1);
    padding-left: 20px;
  }
}

.cloud-card-title {
  display: flex;
  align-items: center;
  font-size: 20px;
  justify-content: space-between;

  div:first-child {
    display: flex;
    align-items: center;
  }

  .anticon {
    font-size: 40px;
    margin-right: 15px;
  }
}

.info-block {
  .info-detail {
    color: $grey6;
    font-size: 14px;
  }
}

.cloud-detail-info {
  background: white;
  padding: 25px 30px;
  box-shadow: 3px 3px 5px 0 rgba(0, 0, 0, 0.1);
  margin-bottom: 40px;
  display: flex;
  flex-direction: column !important;


  .cloud-detail-sub-title {
    color: rgba(0, 0, 0, 0.3);
  }

  .cloud-detail-info-block {
    display: flex;
    flex-direction: row;
    align-items: center;

    &.outlined {
      .icon-block {
        background: transparent;
        border: none;
      }
    }

    .cloud-detail-info-description {
      display: flex;
      flex-direction: column;
      justify-content: center;
    }

    .icon-block {
      width: 60px;
      height: 60px;
      border-radius: 5px;
      margin-right: 15px;
      font-size: 40px;
      text-align: center;
      color: white;
      background: $accent-blue1;
      border: 2px solid $accent-blue1;

      .anticon {
        position: relative;
        top: -5px;
      }
    }
  }
}

.cloud-detail-row {
  .ant-col {
    &:first-child {
      padding-left: 0;
    }
  }
}


