@import "../../../assets/index.scss";

.ant-card.messages-card {
  height: 100%;
  box-shadow: 3px 3px 5px 0px rgba(0, 0, 0, 0.1);
  min-width: 300px;
  border: none;

  .ant-card-body {
    padding: 0 24px 18px;
    max-height: 400px;
    min-height: 70px;
    overflow-y: auto;
    display: flex;

    .ant-spin-nested-loading {
      flex: auto;

      .ant-spin-container {
        box-shadow: none;
        flex: auto;
      }
    }
  }

  .ant-card-head-title {
    color: $primary;
  }

  .ant-card-head {
    border: none;

    &:after {
      width: 100%;
      border: 1px solid rgba(0, 0, 0, 0.1);
      margin-bottom: 10px;
    }
  }

  .card-header-view-all {
    color: $primary;
    font-size: 16px;
    border: none;
    box-shadow: none;
    padding: 3px;

    :hover {
      color: $primary-hover;
    }
  }
}

.ant-card .ant-card-actions {
  margin: 0 24px;
  font-size: 12px;
  color: #7c8695;

  > li {
    text-align: right;
    margin: 4px 0 16px;

    button {
      font-size: 12px;
      color: #7c8695;
      border: none;
      padding: 0;
    }
  }
}

.message-row {
  min-height: 60px;
  border-radius: 2px;
  border-bottom: 1px solid #e3e7ea;
  padding: 10px 0;

  &:last-child {
    border-bottom: none;
  }

  &:hover {
    background-color: #f1f5f8;
  }
}

.message-row.active {
  background-color: #f1f5f8;
  border-left: 4px solid #2c6bcc;
}

.message-content {
  align-items: center;
  display: flex;

  svg {
    position: relative;
    margin-right: 20px;
    height: 32px;
    width: 32px;
  }

  padding-left: 8px;
  max-width: 350px;

  span {
    display: block;
  }

  &__title {
    font-size: 16px;
    font-weight: 600;
    color: $grey7-primary-text;
  }

  &__body {
    color: #788599;
  }
}

.message-date {
  padding-right: 16px;
  font-size: 12px;
  color: #9ca5b4;
}
