@import "../../assets/index.scss";

.table-button {
  border-radius: 0px !important;
}

.manage-button {
  color: white !important;
  background-color: #006dc9 !important;
  border-color: #006dc9 !important;
}
