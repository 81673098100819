@import "../../assets/index.scss";

.ant-modal {
  top: 40px !important;

  .ant-modal-content {
    box-shadow: 1px 4px 14px 0 rgba(0, 0, 0, 0.34);

    .ant-modal-header {
      padding: 19px 40px;
      background-color: white;
      border-bottom: none;
      color: $primary;
      box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.1);
      margin-bottom: 10px;

      .ant-modal-title {
        color: inherit;
        font-size: 22px;
        font-weight: normal;
      }
    }

    .ant-modal-close-x {
      opacity: 0.6;
      width: 46px;
      height: 46px;
      line-height: 46px;
      color: white;
    }

    .ant-modal-body {
      padding: 19px 40px 32px;

      &:after {
        margin: 32px 0 0;
        content: '';
        display: block;
        width: 100%;
        height: 1px;
        background: $grey3;
      }
    }

    .ant-modal-footer {
      border-top: none;
      padding: 0 30px 28px;
      display: flex;
      justify-content: flex-end;

      button {
        width: 150px;
        height: auto;
        border-radius: 3px;
        font-size: 16px;
      }

      .footer-text {
        position: absolute;
        left: 7%;
        color: $grey5
      }
    }
  }

  .ant-form {
    .ant-row {
      .ant-col {
        &.modal-col {
          margin-bottom: 32px;
        }
      }
    }
  }


  .ant-btn-danger {
    background-color: $error !important;
  }

  .ant-btn-primary {
    background-color: $primary;
    border-color: $primary;
    font-weight: 600 !important;

    &:hover {
      background-color: $primary-hover;
      border-color: $primary-hover;
    }
    &:disabled {
      border-color: #d9d9d9;
      background: #f5f5f5;
    }
  }

  .ant-btn-secondary {
    margin-left: 12px;
    background-color: white;
    color: $primary;
    border: 1px solid $primary;
    font-weight: 600;

    &:hover {
      background: $secondary-hover-fill;
      border-color :$primary-hover;
      color: $primary-hover;
    }
  }

  .ant-slider.modal-item-slider {
    margin-bottom: 16px;

    .ant-slider-rail {
      height: 5px;
      border-radius: 4.5px;
      background: #c6d1da;
    }

    .ant-slider-handle {
      margin-top: -8px;
      width: 22px;
      height: 22px;
      box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.17);
      border: 2px solid white;
      background-color: $primary-hover;
    }
  }

  .ant-form .modal-item-label {
    margin-bottom: 0;

    label {
      font-size: 15px;
      font-weight: 600;
    }
  }

  .secured-checkbox {
    margin-top: 30px;
  }

  .ant-form-vertical .modal-item-label .ant-form-item-label {
    padding-bottom: 8px;
    height: 27px;

    label {
      color: $grey7-primary-text;
    }
  }

  hr {
    border: none;
    border-bottom: 1px solid $grey5;
    margin: 20px 0;
  }

  .static-text {
    font-size: 13px;
    font-weight: 600;
    color: $primary-hover;
  }

  .delete-network-modal-icon {
    margin: 10px auto 15px;
    width: 100px;
    height: 100px;
    border-radius: 50%;
    background: $primary-hover;

    svg {
      width: 60px;
      height: 60px;
      color: white;
    }
  }

  .delete-network-modal-text {
    padding: 0 0px;
    font-size: 20px;
    line-height: 28px;
    font-weight: 400;
    color: #000000;
  }

  .delete-cluster-info-container {
    background-color: $grey2;
    overflow: scroll;
    overflow-x: hidden;
    max-height: 250px;
    padding: 0.5em;

    .delete-cluster-info-text {
      text-indent: 30px;
      line-height: 20%;
      color: #000000;
    }
  
    .delete-cluster-info-heading {
      font-weight: bold;
      color: #000000;
    }
  }

}

.ant-input-number.modal-item-controller {
  width: 100%;
  display: flex;
  align-items: center;
}

.ant-input-password.modal-item-controller,
.ant-input.modal-item-controller,
.ant-input-number.modal-item-controller,
.ant-input-affix-wrapper.modal-item-controller,
.ant-select.modal-item-controller {
  border-radius: 4px;
  border: 1px solid $grey5;
  min-height: 40px !important;
  font-size: 15px;
  transition: none;
  box-shadow: none;

  &:hover {
    border-width: 2px;
    border-color: $grey5;
    border-right-width: 2px !important;
  }

  &:focus {
    outline: none;
    box-shadow: none;
    border-color: $primary;
    border-width: 2px !important;
    margin-right: 2px;
    caret-color: $primary;
    -webkit-box-shadow: 0 0 0px 1000px #fff inset;
  }
}

.modal-item-controller {
  transition: none;
  background: #fff;

  &.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    border: 1px solid $grey5;
    height: 100%;
    font-size: 15px;
    border-radius: 4px;
    transition: none;

    .ant-select-selection-item {
      align-self: center;
    }
  }

  &.ant-select-disabled {
    background: $grey2;
    color: $grey5;

    &.ant-select:not(.ant-select-customize-input) .ant-select-selector {
      border: none;
      background: unset;
    }
  }

  &.ant-select:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector {
      border: none;
      background-color: unset;
  }

  &.ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) {

    box-shadow: none;
    outline: none;
    border-color: $primary;
    border-width: 2px !important;
    margin-right: 2px;
    caret-color: $primary;

    .ant-select-selector {
      box-shadow: none;
      outline: none;
      border-color: $primary;
      border-width: 2px !important;
      margin-right: 2px;
      caret-color: $primary;
    }
  }
}

.ant-form-item-has-error {
  .ant-input-password.modal-item-controller,
  .ant-input.modal-item-controller,
  .ant-input-affix-wrapper.modal-item-controller,
  .ant-select.modal-item-controller {
    border-color: $error;
  }
}

.ant-select.modal-item-controller {
  .ant-select-selection-search {
    display: flex;
    align-items: center;
    .ant-select-selection-search-input {
      display: flex;
      align-items: center;
    }
  }
}

.ant-form-item-control {
  .ant-form-item-explain {
    margin-top: 4px;

    &.ant-form-item-explain-error {
      color: $grey7-primary-text;
      font-weight: 600;
      display: flex;
      flex-direction: column;

      div[role="alert"] {
        &::before {
          content: 'Error:';
          color: $error;
          margin-right: 5px;
        }
        &:last-child {
          margin-bottom: 10px;
        }
      }
    }
  }
}
.ant-form-item-label {
  label {
    &.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
      display: none;
    }
    &.ant-form-item-required-mark-optional:not(.ant-form-item-required) {
      width: 100%;

      .ant-form-item-optional {
        display: none;
      }

      &::after {
        content: 'Optional';
        display: block;
        position: absolute;
        right: 0;
        margin-right: 0;
        font-weight: 400;
        font-style: italic;
        color: $grey6;
        font-size: 14px;
        top: 3px;
      }
    }
  }
}

.delete-body {
  margin-bottom: 32px;
}

.generate-group {
  .ant-input-group-addon {
    padding: 0 0px;
    border: 0px;
  }
}

.copy-group {
  .ant-input-group-addon {
    padding: 0 0px;
    border: 0px;
  }
  .ant-btn {
    width: 32px;
    min-width: 32px;
  }
  .ant-select {
    min-width: 100px;
  }
}

.boot-key-label {
  font-size: 15px;
  font-weight: 600;
}