@import "~antd/dist/antd.css";
@import "./assets/index.scss";

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 16px;

  ::-webkit-scrollbar {
    width: 6px;
  }

  ::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 20px;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #bbb;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #888;
  }

  > div {
    //min-height: 100%;
  }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

.w-100 {
  width: 100% !important;
}

.m-0 {
  margin: 0;
}

.p-0 {
  padding: 0 !important;
}

.d-flex {
  display: flex;
}

.flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.flex-column {
  flex-direction: column;
}

@mixin notification-base($backgroundColor, $blockColor) {
  position: relative;
  background: $backgroundColor;
  width: unset;
  min-width: 384px;
  max-width: 819px;

  &:before {
    content: '';
    display: block;
    height: 100%;
    width: 10px;
    position: absolute;
    background: $blockColor;
    top: 0;
    left: 0;
  }
}

.notification-error {
  @include notification-base($error-light, $error);
}

.notification-success {
  @include notification-base($success-light, $success);
}

.notification-warn {
  @include notification-base($warning-light, $warning);
}

.notification-info {
  @include notification-base($information-light, $information);
}

.ant-notification {
  padding: 5px 10px 5px 20px;

  .ant-notification-notice {
    min-height: 64px;
    display: flex;
    align-items: center;

    .ant-notification-notice-close {
      top: 23px;
    }
  }
  .ant-notification-notice-with-icon {
    display: flex;
    align-items: center;
    margin-bottom: 0;

    .ant-notification-notice-message {
      font-size: 14px;
      margin-bottom: 0;
    }
  }
}

.welcome-message {
  margin-bottom: 25px;
  font-size: 14px;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus
{
  background-color: #fff !important;
  -webkit-text-fill-color: $grey7-primary-text;
  -webkit-box-shadow: 0 0 0px 1000px #fff inset;
}

.ant-notification.ant-notification-bottom {
  width: 100%;

  .ant-notification-notice {
    margin-right: auto;
    margin-left: auto;
  }
}

.ant-btn-danger {
  background-color: $error !important;
}

.ant-btn-primary {
  background-color: $primary;
  border-color: $primary;
  font-weight: 600 !important;

  &:hover {
    background-color: $primary-hover;
    border-color: $primary-hover;
  }
}

.ant-btn-secondary {
  margin-left: 12px;
  background-color: white;
  color: $primary;
  border: 1px solid $primary;
  font-weight: 600;

  &:hover {
    background: $secondary-hover-fill;
    border-color :$primary-hover;
    color: $primary-hover;
  }
}

.ant-btn {
  min-width: 152px;

  &.refresh-button {
    min-width: 40px;
  }
}