@import '../../../../assets/index.scss';

.no-cluster-box {
  padding: 0 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: relative;
  width: 600px;
  margin: auto;

  .no-cluster-message {
    color: $grey7-primary-text;
    border-radius: 2px;
    margin-bottom: 30px;
    display: flex;

    .anticon {
      color: $warning;
      font-size: 30px;
      margin-right: 20px;
      position: relative;
      top: 6px;
    }
  }

  .button-container {
    position: relative;
    width: 100%;
    display: flex;

    .skip-btn {
      position: absolute;
      left: 0;
      margin-left: 0;
    }

    .claim-cluster-btn {
      position: absolute;
      right: 0;
    }
  }
}