@import '../../assets/index.scss';


.ports-container {
  .ant-spin-container {
    box-shadow: none;
  }

  .ports-row {
    box-shadow: none;
    padding-right: 20px;
  }

  .ports-column {
    div:last-child {
      hr {
        display: none;
      }
    }
  }

  .port-status-title {
    color: #a3a9b3;
    font-size: 12px;
    padding: 8px 0;
  }

  .port-title {
    padding-right: 0.5rem;
    color: $grey7-primary-text;
    font-size: 16px;
    flex: auto;
  }

  .port-speed-details {
    margin-top: 10px;
    flex: auto;
    justify-content: space-between;
    align-items: center;
  }

  .port-speed,
  .subport-speed {
    color: #67758c;
    font-size: 12px;
    font-weight: 600;
  }

  .subport-title {
    margin-bottom: -8px;
    color: $grey7-primary-text;
    font-size: 14px;
  }

  .expanded-port {
    padding: 12px 4px 0 16px;
  }

  .ant-divider-vertical.left-guideline {
    position: absolute;
    top: 20px;
    border-left: 1px solid $primary-hover;
    height: calc(100% - 40px);
  }

  .ant-btn.btn-portspeed {
    border-radius: 2px;
    width: 60px;
    height: 26px;
    padding: 0;
    background-color: #e3e7ea;
    font-size: 11px;
    font-weight: 600;
    color: #67758c;
  }

  .ant-btn.active.btn-portspeed {
    background-color: $success;
    color: white;
  }
}

.switch-table {
  .ant-table-row-expand-icon-cell {
    padding-left: 0 !important;
  }
}