@import "../../../assets/index.scss";

.wizard-container {
  .wizard-content {
    .hr-separator {
      margin: 20px 12px 30px;
      width: 100%;
    }

    .static-text {
      font-weight: 600;
    }

    .checkbox {
      background: transparent;
    }

    .p {
      font-weight: 600;
    }
  }
}

.info-col {
  font-size: 16px;
}

.addEnv-container {
  padding-top: 0px;
}

.env-details {
  font-weight: bold;
}

.ant-row.ant-form-item.pairingTextArea {
  margin-bottom: 4px;
}