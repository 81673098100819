@import "../../assets/index.scss";


.header-breadcrumb {
  margin-bottom: 15px;

  .ant-btn {
    height: 40px;
    background-color: $primary;
    color: white;
    font-size: 15px;
    font-weight: 600;
    border-color: $primary;

    &:hover, &:focus, &:active {
      background-color: $primary-hover;
      border-color: $primary-hover;
      color: white;
    }

    &.add-button {
      margin-left: 5px;
      min-width: 152px;
      
      &[disabled] {
        color: rgba(0, 0, 0, 0.25);
        border-color: $grey4;
        background: $grey2;
      }
    }

    &.refresh-button {
      .ant-spin {
        color: #fff;
        font-size: 10px;

        svg {
          width: 16px;
        }
      }
      .anticon-redo {
        transform: rotate(-45deg)
      }
    }
  }

  .search-box {
    border-radius: 3px;
    border: none;

    .ant-input-prefix .anticon-search {
      color: #9da1a9;
      padding-right: 4px;
    }
    input::placeholder {
      font-style: italic;
    }
  }

  .header-section {
    display: flex;
    flex-direction: row;
    color: $primary;
    align-items: baseline;
    font-weight: 600;

    .header-item-count {
      font-size: 26px;
      margin-right: 5px;
    }
  }
}


