@import "../../assets/index.scss";

.search-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;

  .anticon-search {
    z-index: 2;
  }

  .ant-input {
    border: none;
    border-bottom: 1px solid $grey3;
    background: transparent;

    &:focus {
      outline: none;
      box-shadow: none;
      border-bottom: 1px solid $primary;
    }
  }
}