@import "../../assets/index.scss";

.status-icon {
  width: 11px;
  height: 11px;
  border-radius: 50%;
}

.ok {
  background-color: $success;
}

.hold {
  background-color: #ffa700;
}

.standby {
  background-color: $warning;
}

.fail {
  background-color: $error;
}

.offline {
  background-color: $grey5;
}

.unavailable {
  background-color: purple;
}

.status-body {
  padding-left: 8px;
}
