@import "../../assets/index.scss";

.cluster-container {
  .cluster-detail-row {
    justify-content: start !important;

    .ant-col-8 {
      min-width: 350px !important;
    }

    .cluster-detail-hidden-col {
      div {
        padding: 0;
        background: transparent;
        box-shadow: none;
      }
    }
  }

  .deployment-container {
    .applications {
    }
  }

  .cluster-details-container {
    background: white;
    padding: 20px;
    box-shadow: 3px 3px 5px 0 rgba(0, 0, 0, 0.1);

    .stats-sub-title {
      color: rgba(0, 0, 0, 0.3);
    }
  
    .stats-info-block {
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 10px 0px;
      border-bottom: 1px solid $grey3;
  
      .stats-info-description {
        display: flex;
        flex-direction: column;
        justify-content: center;
      }
  
      .stats-value {
        font-size: 16px;
      }
    }
  }
}

.extended-footer {
  margin-top: 30px;
  border-top: 1px solid $grey4-disabled;
  padding-top: 20px;
  background: white;

  .action-btn {
    border-radius: 3px;
    border: 1px solid $primary;
    margin-right: 20px;
    font-weight: 600;
    background: $primary;
    color: white;
  }

  .destroy-btn {
    border-radius: 3px;
    background-color: $error;
    font-weight: 600;
  }
}

.node-list,
.service-list,
.deployment-list {
  overflow: auto;
}

.service-container,
.deployment-container,
.node-container {
  //background: white;
  //padding: 20px 30px;
  height: 100%;
  margin-bottom: 40px;
  //box-shadow: 3px 3px 5px 0px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;

  .ant-table {
    .ant-table-container {
      table {
        width: 100%;
      }

      &:after {
        box-shadow: none;
      }
    }

    .ant-table-tbody {
      .ant-table-row {
        .ant-table-cell {
          &:last-child {
            text-align: left;
          }
        }
      }
    }
    .ant-table-tbody > tr > td {
      word-wrap: break-word;
      word-break: break-all;
    }
  }

  .ant-spin-container {
    box-shadow: none !important;
  }

  .header {
    color: $primary;

    .row {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      height: 32px;
    }

    &:after {
      content: '';
      display: block;
      border-bottom: 1px solid $grey3;
      padding-bottom: 10px;
    }
  }

  .no-data-container {
    margin-top: 40px;
  }
}

.node-container {
  .node-header-description {
    display: flex;
    align-items: baseline;
    line-height: 1;
    font-weight: 600;

    .item-count {
      font-size: 26px;
      margin-right: 5px;
    }

    .anticon-info-circle {
      position: relative;
      margin-left: 10px;
      top: 4px;

      svg {
        width: 18px;
        height: 18px;
      }
    }
  }
}

.service-container {
  .ant-checkbox-wrapper {
    display: flex;
    align-items: center;

    > span:nth-child(2) {
      padding-left: 8px;
      justify-content: space-between;
      display: flex;
      align-items: center;
      width: 100%;
    }
  }

  .ant-checkbox-inner {
    width: 12px;
    height: 12px;
  }

  .service-header-description {
    color: $primary;
    display: flex;
    align-items: baseline;
    line-height: 1;
    font-weight: 600;

    .item-count {
      font-size: 26px;
      margin-right: 5px;
    }
  }

  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: $success;
    border-color: $success;
    width: 12px;
    height: 12px;
    border-radius: 2px;
  }

  .ant-checkbox-checked.ant-checkbox-disabled .ant-checkbox-inner {
    background-color: $grey2;
  }

  .ant-btn{
    
    .btn-green {
      display: flex;
      height: 23px;
      padding: 0 8px;
      border-radius: 2px;
      box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.17);
      font-size: 12px;
      font-weight: 600;
      color: rgba(0, 0, 0, 0.25);

      &:not([disabled]) {
        background-color: $success;
        color: white;
      }
    }

    .action-btn {
      min-width: 50px;
      padding: 0;
    }
  }

  .ant-checkbox-wrapper:hover .ant-checkbox-inner,
  .ant-checkbox:hover .ant-checkbox-inner,
  .ant-checkbox-input:focus + .ant-checkbox-inner {
    border-color: $success;
  }

  .service-name {
    padding-left: 16px;
    font-size: 14px;
    font-weight: 600;
    color: $grey7-primary-text;
  }

  .service-description {
    margin-bottom: 8px !important;
    padding-left: 16px;
    padding-bottom: 12px;
    border-bottom: 1px solid #e3e7ea;
    font-size: 12px;
    color: #67758c;
  }

  .service-list {
    overflow: hidden;
  }
}

.node-table,
.service-table {
  height: 100%;

  .ant-table {
    height: 100%;

    .ant-table-container {
      height: 100%;

      table {
        .ant-table-thead {
          th {
            //height: 30px;
          }
        }
      }
    }

    .ant-table-column-has-sorters {

      .ant-table-column-sorters {
        padding: 0 16px 0 0 !important;
      }
    }
  }
}


.deployment-container {
  overflow: hidden;

  .deployment-list {
    position: relative;
    margin-top: 20px;
    overflow-x: hidden;
  }

  .add-btn {
    border-radius: 3px;
    border: 1px solid $primary;
    font-weight: 600;
    background: $primary;
    color: white;
  }

  .applications {
    margin-bottom: 20px;
    text-align: start;
    width: 100%;

    .ant-avatar {
      width: 40px;
      height: 40px;
      top: 4px;
    }

    .application-name {
      font-weight: 600;
    }

    .application-description {
      font-size: 12px;
    }
  }
}

.cluster-detail-info {
  background: white;
  padding: 25px 30px;
  box-shadow: 3px 3px 5px 0 rgba(0, 0, 0, 0.1);
  margin-bottom: 40px;
  display: flex;
  flex-direction: column !important;
  min-width: 1010px;

  .cluster-detail-sub-title {
    color: rgba(0, 0, 0, 0.3);
  }

  .cluster-detail-info-block {
    display: flex;
    flex-direction: row;
    align-items: center;

    .cluster-detail-info-description {
      display: flex;
      flex-direction: column;
      justify-content: center;
    }

    .icon-block {
      width: 60px;
      height: 60px;
      border-radius: 5px;
      margin-right: 15px;
      font-size: 40px;
      text-align: center;
      color: white;
      background: $accent-blue1;
      border: 2px solid $accent-blue1;

      .anticon {
        position: relative;
        top: -5px;
      }
    }
  }
}

.cluster-detail-row {
  min-width: 1050px;

  .ant-col {
    &:first-child {
      padding-left: 0;
    }
  }
}

.ant-progress {
  margin-bottom: 10px;
  height: 4px;
  margin-top: -4px !important;

  .ant-progress-outer {
    .ant-progress-inner {
      border-radius: 0;
      position: absolute;
      top: -1px;

      .ant-progress-bg {
        height: 4px !important;
        border-radius: 0;
        background-color: $accent-blue1;
      }
    }
  }
}

.cluster-not-available {
  .message-container {
    display: flex;
    align-items: center;

    .anticon-exclamation-circle {
      color: red;
      font-size: 30px;
      margin-right: 15px;
    }
  }

  .view-clusters-btn {
    margin-top: 20px;
  }
}

.ant-spin-container .ant-table .ant-table-container table .ant-table-tbody tr td.action-column .node-btn {
  min-width: 80px;
}