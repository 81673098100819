@import "../../../assets/index.scss";

.wizard-container {
  .wizard-content {
    .profile-item {
      .ant-form-item-label {
        margin-bottom: 0;

        label {
          display: flex;
          justify-content: space-between;
        }
      }
    }
  }
}