@import "../../../assets/index.scss";

.wizard-container {
  .wizard-content {
    .profile-item {
      width: 360px !important;

      .ant-form-item-label {
        margin-bottom: 0;

        label {
          display: flex;
          justify-content: space-between;

          .anticon {
            font-size: 20px;
          }
        }
      }
    }
  }
}