@import "../../../assets/index.scss";

.wizard-container {
  .wizard-content {
    .hr-separator {
      margin: 20px 12px 30px;
      width: 100%;
    }

    .static-text {
      font-weight: 600;
    }

    .checkbox {
      background: transparent;
    }

    .port-container {
      margin-bottom: 20px;

      .port-checkbox-container {
        display: flex;
        flex-direction: row;

        .ant-form-item {
          margin-right: 20px;
        }
      }

      .modal-item-label {
        margin-bottom: 10px;
      }
    }

    .gateway {
      .ant-form-item-required-mark-optional {
        &::after {
          display: none;
        }
      }
    }
  }
}