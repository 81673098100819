@import '../../assets/index.scss';

.onboarding-container,
.onboarding-signup-container,
.onboarding-overview-container,
.onboarding-cluster-container,
.onboarding-credentials-container {
  display: flex;
  align-items: center;
  justify-content: center;

  .ant-form {
    width: 450px;
  }

  .info-box {
    width: 100%;
    min-height: 100px;
    border: 1px solid $grey5;
    border-radius: 2px;
    margin-bottom: 32px;
    background: $grey1;
    position: relative;
  }

  .wizard-btn {
    margin-top: 20px;
  }

  .ant-checkbox-wrapper {
    background: none;
  }
}

.onboarding-container {
  flex-direction: column;
  color: $grey7-primary-text;
  display: flex;
  align-items: center;
  height: 100%;
  justify-content: flex-start;

  .background {
    content: '';
    display: block;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background: white;
  }
  
  .marketing-row {
    
    h1 {
      font-size: 30px;
      color: $primary;
    }

    h2 {
      font-size: 20px;
      margin-top: 20px;
      color: $accent-blue1;
    }

    .ant-btn {
      margin-top: 10px;
    }

    .img-col {
      display: flex;
      align-items: center;
      justify-content: center;

      img {
        width: 90%;
        margin-top: 30px;
        margin-bottom: 50px;
        box-shadow: 2px 4px 20px 0 rgba(0,0,0,0.5);
      }
    }

    .how-link {
      color: $primary-hover;
      font-weight: 600;
      font-size: 1rem;
    }

    .quote {
      font-style: italic;
      padding-top: 2em;
      color: $grey5;
    }
  }

  .feature-row {

    .feature-col {
      margin-top: 10px;
      display: flex;
      flex-direction: column;
      text-align: center;
      align-items: center;
      color: $grey7-primary-text;
      min-height: 300px;

      h4 {
        margin-top: 20px;
        color: $accent-blue1;
      }

      .anticon {
        font-size: 40px;
        margin-bottom: 20px;
        color: $primary;
      }

      .feature-icon {
        width: 60px;
        margin-bottom: -30px;
      }

      div {
        border-left: 1px solid $grey3;
        height: 60px;
        padding: 0 20px;
      }

      &:last-child {
        div {
          border-right: 1px solid $grey3;
        }
      }
    }
  }

}

.onboarding-signup-container {
  .ant-btn {
    margin-left: 12px;
  }

  .eula-box {
    max-height: 200px;
    overflow-y: auto;
    border: 1px solid $grey5;
    border-radius: 2px;
    padding: 20px;
    margin-bottom: 16px;
    margin-top: 16px;
    background: white;
  }

  .checkbox {
    margin-bottom: 16px;
  }
}

.onboarding-cluster-container {
  .step-1 {
    .info-box {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0 20px;
      color: $primary;
      font-weight: bold;
    }
  }

  .step-2 {
    img {
      width: 100%;
    }
  }

  .button-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-top: 10px;

    .skip-btn {
      margin-left: 0;
    }

    .wizard-btn {
      margin-top: 0;
    }
  }
}

.onboarding-credentials-container {
  .info-area {
    .info-box {
      margin-bottom: 10px;
    }
    .ant-btn {
      margin-bottom: 10px;
    }
  }

  .ant-form-item-explain {
    margin-bottom: 20px;
  }
}