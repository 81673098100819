@import '../../assets/index.scss';

.subnet-table {
  .ant-spin-container {
    .ant-table {
      .ant-table-container {

        .ant-table-tbody > tr.ant-table-expanded-row {
          box-shadow: none;
          border: none;
          background: #fff;

          > td {
            padding: 0 20px 20px;

            .ant-table {
              margin: 0;

              thead {
                height: 56px;
                tr {
                  box-shadow: none;
                  th {
                    background: $grey3;

                    .ant-table-column-sorters {
                      color: $grey7-primary-text;
                    }
                  }
                }
              }

              tbody {
                tr {
                  background: $grey2;

                  &:hover {
                    background: $grey1;

                    td {
                      background: $grey1;
                    }
                  }

                  td {
                    border-bottom-color: #fff;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}