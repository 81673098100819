@import '../../assets/index.scss';

.error-container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 100vh;
  color: $primary;

  h1 {
    color: $primary;
  }

  p {
    color: $grey7-primary-text;
  }
}