@import '../../assets/index.scss';

.breadcrumb-container {
  padding: 20px 50px 0 104px;

  .ant-breadcrumb span {
    font-size: 14px;
    font-weight: 600;
    color: $primary-hover;
  }

  .ant-breadcrumb-separator, .ant-breadcrumb-link {
    font-size: 14px;
    font-weight: 600;
    color: $primary;
  }

  .ant-breadcrumb-link.has-link {
    cursor: pointer;
    &:hover {
      color: $accent-blue1;
    }
  }

  .ant-breadcrumb-separator {
    position: relative;
    top: 3px;
    margin-left: 10px;
    margin-right: 10px;
    svg {
      position: relative;
    }
  }
}