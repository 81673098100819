@import "../assets/index.scss";

.ant-layout {
  background-color: $grey2 !important;
  min-height: 100vh !important;
  display: flex;

  .fluid-header {
    position: fixed;
    width: 100%;
    z-index: 102;
  }

  .breadcrumb-container {
    margin-top: 64px;
    z-index: 101;
  }

  .content-layout {
    padding: 15px 50px 65px 104px;
    max-width: 1280px;
    margin-left: auto;
    margin-right: auto;
    min-width: 600px;
    width: 100%;
    height: 100%;
    z-index: 100;
  }

  .ant-layout-header {
    padding-left: 104px;
  }
}

.fluid-footer {
  position: fixed;
  bottom: 0;
  left: 0;
  height: 40px;
  width: 100%;
  border-top: 1px solid $grey8;
  padding-left: 40px;
  color: $footer-grey;
  display: flex;
  align-items: center;
  font-size: 12px;
  background: rgb(255, 255, 255);
  z-index: 103;

  .version-text {
    color: $primary;

    &:hover {
      color: $accent-blue1;
      cursor: pointer;
    }
  }
}